import React, {
  Suspense,
  useEffect,
} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import DefaultLayout from "./layout/DefaultLayout";
import Signup from './components/SignUp/SignUp'
import Admissions24_10_2024 from "./components/compaign/123admissions24_10_2024";
import axios from "axios";
import Livepool from "./components/compaign/liverpool";
import Golden_Gate_University from "./components/compaign/golden_gate_university";
import Admissions_DBA from "./components/compaign/123admissions_dba";
import VIT_admissions from "./components/compaign/vit";
function App() {

  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('sourse'); // Note: there's a typo in "sourse", should be "source"
  const campaign = urlParams.get('campaign'); // Note: there's a typo in "campain", should be "campaign"
  console.log(source, campaign)
  const Campaign_api = async (e) => {

    try {
      const response = await axios.post('https://api.123admissions.com/api/v1/campaign', {
        campaign_name: campaign || 'OR',
        sourse: source || 'OR',
      });

      // setSuccess('Campaign created successfully!');
      // Handle the response further if needed
    } catch (err) {

    } finally {

    }
  };
  useEffect(() => {
    const apiCalled = localStorage.getItem('apiCalled'); // Check localStorage for API call status
    // Campaign_api()
    if (!apiCalled) {
      Campaign_api(); // Call the API if it hasn't been called yet
      localStorage.setItem('apiCalled', 'true'); // Set item in localStorage to indicate API has been called
    }
  }, []);
  return (

    <Router>
      <Routes>
        <Route path="/signup" name="get" element={<Signup />} />
        <Route path="/campaign/online-masters" name="get" element={<Admissions24_10_2024 />} />
        <Route path="/campaign/online-dba" name="get" element={<Admissions_DBA />} />
        <Route path="/campaign/liverpool/master-of-business-Administration" name="get" element={<Livepool />} />
        <Route path="/campaign/golden-gate-university/doctor-of-business-administration" name="get" element={<Golden_Gate_University />} />
        <Route path="/campaign/vellore-institute-of-technology/master-of-science-in-data-science-programme/" name="get" element={<VIT_admissions />} />
        <Route path="/*" name="get" element={<DefaultLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
