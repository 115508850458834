import React, { useState, useRef, useEffect } from "react";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import FormModle from "../FromModle/FormModle";
import './SignUp.css';
import axios from "axios";

const SignUp = () => {
    const [success, setSuccess] = useState(false);
    const [univercity, SetUniversity] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        course: null,
        university: null,
        country: '',
    });
    console.log(formData)
    const [formErrors, setFormErrors] = useState({});

    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (inputRef.current) {
            window.intlTelInput(inputRef.current, {
                initialCountry: 'in',
                separateDialCode: true,
            });
        }
    }, []);
    const universityOptions = univercity.map((data) => ({
        label: data?.universityName,  // Label will be the university name
        value: data?.id   // Value can also be the university name (or some other value if needed)
    }));
    // Course and University options for Select
    const courseOptions = [
        { value: 'BA', label: 'BA' },
        { value: 'BBA', label: 'BBA' },
        { value: 'BCA', label: 'BCA' },
        { value: 'B.Com', label: 'B.Com' },
        { value: 'MBA', label: 'MBA' },
        { value: 'MCA', label: 'MCA' },
        { value: 'M.Com', label: 'M.Com' },
        { value: 'MA.JMC', label: 'MA.JMC' },
        { value: 'MA English', label: 'MA English' },
        { value: 'MA Sociology', label: 'MA Sociology' },
        { value: 'MA Political Science', label: 'MA Political Science' },
    ];



    // Handle form inputs
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (selectedOption, action) => {
        setFormData({ ...formData, [action.name]: selectedOption });
    };

    // Validation rules
    const validateForm = () => {
        let errors = {};

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailPattern.test(formData.email)) {
            errors.email = 'Valid email is required';
        }

        if (!formData.phone) {
            errors.phone = 'Phone number is required';
        }

        if (!formData.course) {
            errors.course = 'Please select a course';
        }

        if (!formData.university) {
            errors.university = 'Please select a university';
        }

        if (!formData.country.trim()) {
            errors.country = 'Country is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            setSuccess(true);
            // Perform the desired submit action (API call, etc.)
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://api.123admissions.com/api/v1/getalluniversity`, {
            });
            SetUniversity(response?.data?.data?.university)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();

    }, []);

    return (
        <>
            <div className="signup-main">
                <div className="signupPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12 mb-3">
                                <h5 style={{ textAlign: "start", fontSize: "19px" }}>Welcome! Sign up or Login</h5>
                            </div>

                            {/* Name Input */}
                            <div className="col-lg-6 col-12 mt-4">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className={`form-control ${formErrors.name && 'is-invalid'}`}
                                />
                                {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                            </div>

                            {/* Email Input */}
                            <div className="col-lg-6 col-12 mt-4">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className={`form-control ${formErrors.email && 'is-invalid'}`}
                                />
                                {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                            </div>

                            {/* Phone Input */}
                            <div className="col-lg-6 col-12 mt-4">
                                <IntlTelInput
                                    inputRef={inputRef}
                                    preferredCountries={['in', 'us']}
                                    onPhoneNumberChange={(isValid, value) => setFormData({ ...formData, phone: value })}
                                    className={`form-control ${formErrors.phone && 'is-invalid'}`}
                                />
                                {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                            </div>


                            {/* University Select */}
                            <div className="col-lg-6 col-12 mt-4">
                                <Select
                                    name="university"
                                    value={formData.university}
                                    onChange={handleSelectChange}
                                    options={universityOptions}
                                    placeholder="Select university"
                                    className={formErrors.university && 'is-invalid'}
                                />
                                {formErrors.university && <div className="invalid-feedback">{formErrors.university}</div>}
                            </div>

                            {/* Course Select */}
                            <div className="col-lg-6 col-12 mt-4">
                                <Select
                                    name="course"
                                    value={formData.course}
                                    onChange={handleSelectChange}
                                    options={courseOptions}
                                    placeholder="Select course"
                                    className={formErrors.course && 'is-invalid'}
                                />
                                {formErrors.course && <div className="invalid-feedback">{formErrors.course}</div>}
                            </div>

                            {/* Country Input */}
                            <div className="col-lg-6 col-12 mt-4">
                                <input
                                    type="text"
                                    name="country"
                                    placeholder="Enter your country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    className={`form-control ${formErrors.country && 'is-invalid'}`}
                                />
                                {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                            </div>

                            {/* Submit Button */}
                            <div className="col-lg-6 col-12 mt-5">
                                <button className="btn btn-primary w-100" onClick={handleSubmit}>Continue</button>
                            </div>
                        </div>
                    </div>

                    {/* Modal component */}
                    {/* <FormModle success={success} setSuccess={setSuccess} /> */}
                </div>
            </div>
        </>
    );
};

export default SignUp;
