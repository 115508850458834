import React from "react";
import Logo from '../assets/Logo/Logo.svg'
import Input_img from '../assets/Logo/search-icon.svg'
import banner from '../assets/LandingPage/top-img.webp'
import FilterUni from '../assets/LandingPage/filter-uni.webp'
import filter from '../assets/LandingPage/filter.webp'
import Layer1 from '../assets/LandingPage/Layer_1.webp'
import clglogo1 from '../assets/UniversityLogo/Andhra University.png'
import clglogo2 from '../assets/UniversityLogo/BIMTECH.png'
import clglogo3 from '../assets/UniversityLogo/Golden Gate University.png'
import clglogo4 from '../assets/UniversityLogo/IIIT Bangalore.png'
import clglogo5 from '../assets/UniversityLogo/Jain.png'
import clglogo6 from '../assets/UniversityLogo/LIBA.png'
import clglogo7 from '../assets/UniversityLogo/Maninipal.png'
import clglogo8 from '../assets/UniversityLogo/bharathidasan-university.png'
import clglogo9 from '../assets/UniversityLogo/upgrad.png'
import month from '../assets/icons/date.svg'
import star from '../assets/icons/Star 8.svg'
import vetor from '../assets/icons/Vector.svg'
import girls from '../assets/Logo/screen_2x.webp'
import partner from '../assets/Logo/Group 141.svg'
import landing from '../assets/LandingPage/Group 160.webp'

import partner1 from '../assets/patners/Group 170.webp'
import partner2 from '../assets/patners/Group 171.webp'
import partner3 from '../assets/patners/Group 172.webp'
import partner4 from '../assets/patners/Group 191.webp'
import partner5 from '../assets/patners/Group 174.webp'
import video from '../assets/aboutus/video.webp'
import OurJourney from '../assets/aboutus/ourjurney.webp'
import patnership from '../assets/aboutus/patner.webp'
import Groupimg from '../assets/aboutus/groupimg.webp'
import backgroundImg from '../assets/aboutus/Group 189.webp'
import germany from '../assets/country/germany_5315745.webp'
import canada from '../assets/country/canada_9906451.webp'
import finland from '../assets/country/finland_16022600.webp'
import usa from '../assets/country/usa_4628635.webp'
import Indiaflag from '../assets/country/india.webp'
import frances from '../assets/country/france.webp'
import campaignimg from './compaignimg.webp'
import top from './LandingPage/top-Image.webp'
import landingpost from './380609898_11480533 1 (1).webp'
import pathinfo from './pathway.webp'
import Liverpool from "./livepool.jpeg";
import Liverpool1 from './livepool-1.jpeg'
import Liverpool2 from './livepool-2.jpeg'
import Livepoollogo from './liverpoolLogo.webp'
import Liverpoolel from './Illustration.webp'
import GUU1 from './ggu-1.webp'
import Guu2 from './ggu-2.webp'
import GUU3 from './GUU3.jpg'
import vitlogo from './vellore-institute-of-technology-vit-logo-vector-2022.png'
import Vitbackground from './image 11.webp'
import vitbackground1 from './image 12.webp'
import vitbackground2 from './image 13.webp'
import ioo from './io.png'
import vitcertificate from './vitcertificate.png'
import vitmcd from './2150169834.jpg'
import vitMCAP from './person-office-analyzing-checking-finance-graphs.jpg'
import vitmap from './teacher-smart-instructor-grey-suit-classroom-with-computer-whiteboard-buttoning-suit.jpg'
const Assets = {
    Logo: Logo,
    Search_input: Input_img,
    banner: banner,
    FilterUni: FilterUni,
    filter: filter,
    Layer1: Layer1,
    clglogo1: clglogo1,
    clglogo2: clglogo2,
    clglogo3: clglogo3,
    clglogo4: clglogo4,
    clglogo5: clglogo5,
    clglogo6: clglogo6,
    clglogo7: clglogo7,
    clglogo8: clglogo8,
    clglogo9: clglogo9,
    star: star,
    month: month,
    vetor: vetor,
    girls: girls,
    partner: partner,
    landing: landing,
    partner1: partner1,
    partner2: partner2,
    partner3: partner3,
    partner4: partner4,
    partner5: partner5,
    Video: video,
    OurJourney: OurJourney,
    patnership: patnership,
    Groupimg: Groupimg,
    backgroundImg: backgroundImg,
    Indiaflag: Indiaflag,
    germany: germany,
    canada: canada,
    finland: finland,
    usa: usa,
    frances: frances,
    campaignimg: campaignimg,
    top: top,
    landingpost: landingpost,
    pathinfo: pathinfo,
    Liverpool: Liverpool,
    Liverpool1: Liverpool1,
    Liverpool2: Liverpool2,
    Livepoollogo: Livepoollogo,
    Liverpoolel: Liverpoolel,
    GUU1: GUU1,
    GUU2: Guu2,
    GUU3: GUU3,
    vitlogo: vitlogo,
    Vitbackground: Vitbackground,
    vitbackground1: vitbackground1,
    vitbackground2: vitbackground2,
    ioo: ioo,
    vitcertificate: vitcertificate,
    vitmcd: vitmcd,
    vitMCAP: vitMCAP,
    vitmap: vitmap

}
export default Assets