import React, { useState, useEffect } from 'react';
import './Timeline.css';

const fields = {
  title: 'Yearly Horizontal Timeline',
  years_timeline: [
    {
      year: 'MARCH 2015',
      body: 'Incorporated, Created Adserver',
    },
    {
      year: 'FEBRUARY 2016',
      body: 'Real-time bidding of advertisement',
    },
    {
      year: 'JANUARY 2018',
      body: 'Created CRM',
    },
    {
      year: 'MAY 2019',
      body: 'Created Ed-tech platform',
    },
    {
      year: 'SEPTEMBER 2020',
      body: 'Admission Partners with Liba Jain University',
    },
    {
      year: '2021-2024',
      body: 'Online Manipal University, Gold Gate University, Upgrad',
    },
  ],
};

function Timeline() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { years_timeline } = fields;

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : years_timeline.length - 1
    );
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex < years_timeline.length - 1 ? prevIndex + 1 : 0
    );
  };

  useEffect(() => {
    // Scroll to selected timeline item if necessary
  }, [selectedIndex]);

  return (
    <div className="timeline">
      <div className="timeline__controls">
        <button onClick={handlePrevious} aria-label="Previous">
          &larr;
        </button>
        <button onClick={handleNext} aria-label="Next">
          &rarr;
        </button>
      </div>
      <div className="timeline__container">
        <ul className="timeline__list">
          {years_timeline.map((item, index) => (
            <li
              key={index}
              className={`timeline__item ${
                index === selectedIndex ? 'timeline__item--active' : ''
              }`}
              onClick={() => setSelectedIndex(index)}
            >
              <span className="timeline__year">{item.year}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="timeline__content">
        <h4>{years_timeline[selectedIndex].year}</h4>
        <p>{years_timeline[selectedIndex].body}</p>
      </div>
    </div>
  );
}

export default Timeline;
