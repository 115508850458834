import React, { useEffect, useState } from "react";
import AppContent from "../components/AppContent";
import AppHeader from "../components/appHeader/AppHeader";
import Aboutus from "../components/aboutus/Aboutus";
import Whatapps from '../assets/bot/whatapp.svg'
import { FiPhoneCall } from "react-icons/fi";
import './De.css'
import Loader from "../components/Loader/loader";
import ChatBox from "../pages/Chat/Chat";
import Assets from "../assets/assets";
const DefaultLayout = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [chatfun, setchatfun] = useState(false)
  // const openWhatsApp = () => {
  //   const phoneNumber = '919597725388'; // Replace with the phone number you want to open
  //   const whatsappUrl = `https://wa.me/${phoneNumber}`;
  //   window.open(whatsappUrl, '_blank');
  // };
  // const openCall = () => {
  //   const phoneNumber = '919597725388'; // Replace with the phone number you want to call
  //   const callUrl = `tel:${phoneNumber}`;
  //   window.open(callUrl);
  // };
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // Set success to true after 10 seconds (10000 milliseconds)
  //   const timer = setTimeout(() => {
  //     setLoading(true);
  //   }, 10000);

  //   // Cleanup the timer if the component is unmounted before 10 seconds
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      {
        loading ?
          <div className="App">

            < AppHeader setMenuToggle={setMenuToggle} />
            <div className="wrapper" style={{ overflow: "scroll" }}>

              <div className="body">

                <AppContent />

           
              </div>
            </div>
            <div>
              <Aboutus />
            </div>
          </div > : <Loader />
      }
    </>
  );
};

export default DefaultLayout;
