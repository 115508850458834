// routes.js
import Landingpage from "../pages/landingPage/Landingpage";
import AboutusPage from "../pages/Aboutus/AboutusPage";
import Couses from "../pages/couses/Couses";
import Sample from "../pages/sample";
import CollegeView from "../pages/CollegeView/CollegeView";
import WebinarRegistration from "../pages/studyabroad/WebinarRegistration";
import AbroadUniversity from "../pages/AbroadUnisersity/AbroadUniversity";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import AUpayment from '../pages/AUpayment/AUpayment';

import MobileMenu from "../components/MobilestudyAbroad/MobileMenu";
import BApayment from "../pages/BApayment/BApayment";
import Google from '../pages/GooglePay/Gpay';
import BharathidasanUniversity from '../pages/BharathidasanUniversity/BharathidasanUniversity';
import AndhraUniversity from '../pages/Andhra-University/Andhra-University';
import MGRuniversity from '../pages/MGRuniversity/MGRuniversity';
import ManipalUniversity from '../pages/ManipalUniversity/ManipalUniversity';
import JainOnline from '../pages/JainOnline/JainOnline';
import GoldenGateUniversity from '../pages/GoldenGateUniversity/GoldenGateUniversity';
import IIITBangalore from '../pages/IIITBangalore/IIITBangalore';
import BIMTECH from '../pages/BIMTECH/BIMTECH';
import Liba from '../pages/Liba/Liba';
import ClarkUniversity from '../pages/ClarkUniversity/ClarkUniversity';
import InternationalUniversity from '../pages/InternationalUniversity/InternationalUniversity';
import ESDESBusinessSchool from '../pages/ESDESBusinessSchool/ESDESBusinessSchool';
import Aivancity from '../pages/Aivancity/Aivancity';
import NortheasternUniversity from '../pages/NortheasternUniversity/NortheasternUniversity';
import HaagaHeliaUniversity from '../pages/HaagaHeliaUniversity/HaagaHeliaUniversity';
import VaasaUniversity from '../pages/VaasaUniversity/VaasaUniversity';
import MetropoliaUniversity from '../pages/MetropoliaUniversity/MetropoliaUniversity';
import SeinajokiUniversity from '../pages/SeinajokiUniversity/SeinajokiUniversity';
import InternationalBusinessUniversity from '../pages/InternationalBusinessUniversity/InternationalBusinessUniversity';
import ColoradoStateUniversity from '../pages/ColoradoStateUniversity/ColoradoStateUniversity';
import UniversityCourses from '../pages/Adv/UnivercityCourses';
import PGDM from '../pages/Adv/pgdm';
import BDU from '../pages/Adv/BDU';
import AboutusPageSample from '../pages/AboutusPagesample/AboutusPageSample';
import MGRuniversityPayment from '../pages/MGRpayment/MGRpaymnet';
import NotFound from "../pages/NotFound/Notfount";
import Online_MBA_Human_Resource_Management from "../pages/university_courses/Online_MBA_Human_Resource_Management.js";
import Online_MBA_Finance from "../pages/university_courses/Online_MBA_Finance.js";
import Online_MBA_Marketing from "../pages/university_courses/Online_MBA_Marketing.js";
import Online_MBA_IT_FinTech from "../pages/university_courses/Online_MBA_IT_FinTech.js";
import Online_MBA_Operations_Management from "../pages/university_courses/Online_MBA_Operations_Management.js";
import Online_MBA_Analytics_and_Data_Science from "../pages/university_courses/Online_MBA_Analytics_and_Data_Science.js";
import Online_MBA_Information_System_Management from "../pages/university_courses/Online_MBA_Information_System_Management.js";
import Online_MBA_Project_Management from "../pages/university_courses/Online_MBA_Project_Management.js";
import Bachelor_of_Computer_Applications_Online_BCA from "../pages/university_courses/Bachelor_of_Computer_Applications_Online_BCA.js";
import Bachelor_of_Business_Administration_BBA from "../pages/university_courses/Bachelor_of_Business_Administration_BBA.js";
import Bachelor_of_Commerce_BCOM from "../pages/university_courses/Bachelor_of_Commerce_BCOM.js";
import Master_of_Computer_Applications_MCA from "../pages/university_courses/Master_of_Computer_Applications_MCA.js";
import Master_of_Commerce_MCOM from "../pages/university_courses/Master_of_Commerce_MCOM.js";
import MBA_Finance from "../pages/university_courses/MBA_Finance.js";
import MBA_Human_Resource_Management from "../pages/university_courses/MBA_Human_Resource_Management.js";
import MBA_General_Management from "../pages/university_courses/MBA_General_Management.js";
import MBA_Marketing from "../pages/university_courses/MBA_Marketing.js";
import MBA_Finance_and_Marketing from "../pages/university_courses/MBA_Finance_and_Marketing.js";
import MBA_Human_Resource_Management_and_Finance from "../pages/university_courses/MBA_Human_Resource_Management_and_Finance.js";
import MBA_Marketing_and_Human_Resource_Management from "../pages/university_courses/MBA_Marketing_and_Human_Resource_Management.js";
import MBA_Project_Management from "../pages/university_courses/MBA_Project_Management.js";
import MBA_Logistics_Supply_Chain_Management from "../pages/university_courses/MBA_Logistics_Supply_Chain_Management.js";
import MBA_Healthcare_Management from "../pages/university_courses/MBA_Healthcare_Management.js";
import MBA_Information_Technology_Management from "../pages/university_courses/MBA_Information_Technology_Management.js";
import MBA_Business_Intelligence_Analytics from "../pages/university_courses/MBA_Business_Intelligence_Analytics.js";
import MBA_International_Finance_Accredited_by_ACCA_UK from "../pages/university_courses/MBA_International_Finance_Accredited_by_ACCA_UK.js";
import MBA_Entrepreneurship_and_Leadership from "../pages/university_courses/MBA_Entrepreneurship_and_Leadership.js";
import MBA_International_Business_Management from "../pages/university_courses/MBA_International_Business_Management.js";
import MA_Economics from "../pages/university_courses/MA_Economics.js";
import Master_of_Business_Administration_MBA from "../pages/university_courses/Master_of_Business_Administration_MBA.js";
import MA_Public_Administration from "../pages/university_courses/MA_Public_Administration.js";
import MA_Political_Science from "../pages/university_courses/MA_Political_Science.js";
import MA_English from "../pages/university_courses/MA_English.js";
import MA_History from "../pages/university_courses/MA_History.js";
import BBA_Batchelor_of_Business_Administration from "../pages/university_courses/BBA_Batchelor_of_Business_Administration.js";
import BA_English from "../pages/university_courses/BA_English.js";
import MBA_Master_of_Business_Administration from "../pages/university_courses/MBA_Master_of_Business_Administration.js";
import MCA_Master_of_Computer_Application from "../pages/university_courses/MCA_Master_of_Computer_Application.js";
import B_Com_Bachelor_of_Commerce from "../pages/university_courses/B_Com_Bachelor_of_Commerce.js";
import MA_Sociology_Master_of_Arts from "../pages/university_courses/MA_Sociology_Master_of_Arts.js";
import MS_in_Data_Analytics from "../pages/university_courses/MS_in_Data_Analytics.js";
import MS_in_Data_Analytics_online from "../pages/university_courses/MS_in_Data_Analytics_online.js";
import Master_in_Business_Administration_online from "../pages/university_courses/Master_in_Business_Administration_online.js";
import MS_in_Information_Technology_online from "../pages/university_courses/MS_in_Information_Technology_online.js";
import MS_in_Project_Management_online from "../pages/university_courses/MS_in_Project_Management_online.js";
import MS_in_Computer_Science_online from "../pages/university_courses/MS_in_Computer_Science_online.js";
import Master_in_Business_Administration_offline from "../pages/university_courses/Master_in_Business_Administration_offline.js";
import MS_in_Data_Analytics_offline from "../pages/university_courses/MS_in_Data_Analytics_offline.js";
import MS_in_Information_Technology_offline from "../pages/university_courses/MS_in_Information_Technology_offline.js";
import MS_in_Project_Management_offline from "../pages/university_courses/MS_in_Project_Management_offline.js";
import MS_in_Computer_Science_offline from "../pages/university_courses/MS_in_Computer_Science_offline.js";
import Masters_in_Business_Administration_60_ECTS_online from "../pages/university_courses/Masters_in_Business_Administration_60_ECTS_online.js";
import Masters_in_International_Management_online from "../pages/university_courses/Masters_in_International_Management_online.js";
import Masters_in_Computer_science_120_ECTS_online from "../pages/university_courses/Masters_in_Computer_science_120_ECTS_online.js";
import Masters_in_Data_Science_60_ECTS_online from "../pages/university_courses/Masters_in_Data_Science_60_ECTS_online.js";
import Masters_in_Data_Science_60_ECTS_Offline from "../pages/university_courses/Masters_in_Data_Science_60_ECTS_Offline.js";
import Masters_in_Computer_science_120_ECTS_Offline from "../pages/university_courses/Masters_in_Computer_science_120_ECTS_Offline.js";
import Masters_in_International_Management_Offline from "../pages/university_courses/Masters_in_International_Management_Offline.js";
import Masters_in_Business_Administration_60_ECTS_Offline from "../pages/university_courses/Masters_in_Business_Administration_60_ECTS_Offline.js";
import Masters_in_Business_Administration_90_ECTS_Offline from "../pages/university_courses/Masters_in_Business_Administration_90_ECTS_Offline.js";
import Msc_Sustainable_Tourism_Event_Management_Online from "../pages/university_courses/Msc_Sustainable_Tourism_Event_Management_Online.js";
import Msc_in_Circular_Economy_Sustainable_Innovation_Online from "../pages/university_courses/Msc_in_Circular_Economy_Sustainable_Innovation_Online.js";
import Msc_in_Impact_Finance_Fintech_Management_Online from "../pages/university_courses/Msc_in_Impact_Finance_Fintech_Management_Online.js";
import Msc_in_Impact_Finance_Fintech_Management_Offline from "../pages/university_courses/Msc_in_Impact_Finance_Fintech_Management_Offline.js";
import Msc_in_Circular_Economy_Sustainable_Innovation_Offline from "../pages/university_courses/Msc_in_Circular_Economy_Sustainable_Innovation_Offline.js";
import Msc_Sustainable_Tourism_Event_Management_Offline from "../pages/university_courses/Msc_Sustainable_Tourism_Event_Management_Offline.js";
import Msc_in_Data_Engineering_Online from "../pages/university_courses/Msc_in_Data_Engineering_Online.js";
import Msc_in_Data_Engineering_offline from "../pages/university_courses/Msc_in_Data_Engineering_offline.js";
import MS_in_Project_Management_NEU_Canada_IIIT_B_Toronto_Online from "../pages/university_courses/MS_in_Project_Management_NEU_Canada_IIIT_B_Toronto_Online.js";
import MSPM_Canada_Track_IMT_Ghaziabad_Toronto_Online from "../pages/university_courses/MSPM_Canada_Track_IMT_Ghaziabad_Toronto_Online.js";
import MPS_in_Informatics_Toronto_Online from "../pages/university_courses/MPS_in_Informatics_Toronto_Online.js";
import MBA_in_Technology_Innovation_and_Entrepreneurship_Offline from "../pages/university_courses/MBA_in_Technology_Innovation_and_Entrepreneurship_Offline.js";
import MBA_in_Technology_Innovation_and_Entrepreneurship_Online from "../pages/university_courses/MBA_in_Technology_Innovation_and_Entrepreneurship_Online.js";
import MBA_Business_Technologies_Online from "../pages/university_courses/MBA_Business_Technologies_Online.js";
import MBA_Leading_Business_Transformation_Online from "../pages/university_courses/MBA_Leading_Business_Transformation_Online.js";
import MBA_International_Business_Management_Online from "../pages/university_courses/MBA_International_Business_Management_Online.js";
import MBA_Business_Informatics_Online from "../pages/university_courses/MBA_Business_Informatics_Online.js";
import MBA_in_International_Business_Management_Online from "../pages/university_courses/MBA_in_International_Business_Management_Online.js";
import MBA_in_International_Business_Management_Offline from "../pages/university_courses/MBA_in_International_Business_Management_Offline.js";
import MBA_Business_Informatics_Offline from "../pages/university_courses/MBA_Business_Informatics_Offline.js";
import MBA_International_Business_Management_Offline from "../pages/university_courses/MBA_International_Business_Management_Offline.js";
import MBA_Leading_Business_Transformation_Offline from "../pages/university_courses/MBA_Leading_Business_Transformation_Offline.js";
import MBA_Business_Technologies_Offline from "../pages/university_courses/MBA_Business_Technologies_Offline.js";
import MS_Computer_Information_Systems_Online from "../pages/university_courses/MS_Computer_Information_Systems_Online.js";
import MS_Applied_Statistics_in_Data_Science_Online from "../pages/university_courses/MS_Applied_Statistics_in_Data_Science_Online.js";



const routes = [
  {
    path: "/",
    name: "landingpage",
    element: Landingpage,
    exact: true,
    title: "123admissions | No.1 online Education Partner",
    description: "Experience excellence in education with 123admissions in Masters(MBA,MCA,MA,MSE),UG and study abroad. The trusted global partner for students and working professionals who are seeking top-tier educational opportunities can start your journey now!",
    keywords: "Online education,MBA courses,Study Abroad,Education, Free courses,upgrad,Liba,Admissions,Admissions open,Best university, Less fees online MBA, Study in US, Free certificate,Cambridge university, oxford, 123admissions,Students,Edtech,admissions open, college"
  },

  {
    path: "/about",
    name: "AboutUsPage",
    element: AboutusPage,
    exact: true,
    title: "About Us",
    description: "Learn more about our mission, vision, and the team behind My App. Our About Us page provides insights into our values and the services we offer.",
    keywords: "about us, company mission, team, services"
  },
  {
    path: "/sample",
    name: "Sample Page",
    element: Sample,
    exact: true,
    title: "Sample Page - Explore Our Features",
    description: "Explore the Sample page to get a glimpse of the various features and functionalities offered by My App.",
    keywords: "sample, app features, functionalities, preview"
  },
  {
    path: "/collegeview",
    name: "College View",
    element: CollegeView,
    exact: true,
    title: "College View - Detailed Information and Insights",
    description: "View detailed information and insights about a range of colleges to help you make informed educational choices.",
    keywords: "college view, college information, educational choices"
  },
  {
    path: "/WebinarRegistration",
    name: "Webinar Registration",
    element: WebinarRegistration,
    exact: true,
    title: "Webinar Registration - Join Our Educational Webinars",
    description: "Register for our upcoming educational webinars and stay updated with the latest trends and opportunities in higher education.",
    keywords: "webinar registration, educational webinars, online learning"
  },
  {
    path: "/abroad/view",
    name: "Abroad University",
    element: AbroadUniversity,
    exact: true,
    title: "Abroad University - Explore International Education Options",
    description: "Explore universities abroad and find the perfect fit for your international education aspirations.",
    keywords: "abroad universities, international education, study abroad opportunities"
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    element: PrivacyPolicy,
    exact: true,
    title: "Privacy Policy - Protecting Your Data",
    description: "Read our Privacy Policy to understand how we collect, use, and protect your personal data while using My App.",
    keywords: "privacy policy, data protection, user privacy, terms and conditions"
  },
  {
    path: "/Andhra-University/Payment",
    name: "Andhra University Payment",
    element: AUpayment,
    exact: true,
    title: "Andhra University Payment - Secure Payment Portal",
    description: "Make secure payments for Andhra University through our dedicated payment portal.",
    keywords: "Andhra University, payment, secure portal, student fees"
  },
  {
    path: "/Bharathidasan-University/Payment",
    name: "Bharathidasan University Payment",
    element: BApayment,
    exact: true,
    title: "Bharathidasan University Payment - Convenient Payment Options",
    description: "Complete your payments for Bharathidasan University conveniently through our online payment system.",
    keywords: "Bharathidasan University, payment, online payment, student fees"
  },

  {
    path: "/MGR-University/Payment",
    name: "MGR University Payment",
    element: MGRuniversityPayment,
    exact: true,
    title: "MGR University Payment - Manage Your Payments Efficiently",
    description: "Handle your MGR University payments efficiently using our secure and user-friendly payment portal.",
    keywords: "MGR University, payment, secure payment, university fees"
  },

  {
    path: "/menu",
    name: "Mobile Menu",
    element: MobileMenu,
    exact: true,

  },
  {
    path: "/University/BharathidasanUniversity",
    name: "Bharathidasan University",
    element: BharathidasanUniversity,
    exact: true,
    title: "Bharathidasan University - Academic Excellence",
    description: "Discover the academic programs and opportunities at Bharathidasan University, a hub of excellence in education.",
    keywords: "Bharathidasan University, academic programs, education excellence"
  },
  {
    path: "/University/AndhraUniversity",
    name: "Andhra University",
    element: AndhraUniversity,
    exact: true,
    title: "Andhra University - Premier Education",
    description: "Explore Andhra University’s programs and learn about its role as a leading institution in higher education.",
    keywords: "Andhra University, education, academic programs"
  },
  {
    path: "/University/MGRuniversity",
    name: "MGR University",
    element: MGRuniversity,
    exact: true,
    title: "MGR University - Premier Learning Hub",
    description: "Find out more about MGR University’s diverse programs and its commitment to providing high-quality education.",
    keywords: "MGR University, learning hub, educational programs"
  },
  {
    path: "/University/ManipalUniversity",
    name: "Manipal University",
    element: ManipalUniversity,
    exact: true,
    title: "Manipal University - Leading Educational Institute",
    description: "Learn about Manipal University and its extensive range of programs designed to prepare students for success.",
    keywords: "Manipal University, educational programs, success"
  },
  {
    path: "/University/JainOnline",
    name: "Jain Online",
    element: JainOnline,
    exact: true,
    title: "Jain Online - Flexible Learning Opportunities",
    description: "Discover Jain Online’s flexible learning opportunities that cater to your educational needs and goals.",
    keywords: "Jain Online, online learning, flexible education"
  },
  {
    path: "/University/GoldenGateUniversity",
    name: "Golden Gate University",
    element: GoldenGateUniversity,
    exact: true,
    title: "Golden Gate University - Innovative Education",
    description: "Explore the innovative programs and educational opportunities offered by Golden Gate University.",
    keywords: "Golden Gate University, innovative education, academic programs"
  },
  {
    path: "/University/IIITBangalore",
    name: "IIIT Bangalore",
    element: IIITBangalore,
    exact: true,
    title: "IIIT Bangalore - Technology and Innovation",
    description: "Learn about IIIT Bangalore and its focus on technology and innovation in higher education.",
    keywords: "IIIT Bangalore, technology, innovation, higher education"
  },
  {
    path: "/University/BIMTECH",
    name: "BIMTECH",
    element: BIMTECH,
    exact: true,
    title: "BIMTECH - Business and Management Education",
    description: "Get detailed information about BIMTECH and its programs specializing in business and management education.",
    keywords: "BIMTECH, business education, management programs"
  },
  {
    path: "/University/Liba",
    name: "Liba",
    element: Liba,
    exact: true,
    title: "Liba - Advanced Learning Opportunities",
    description: "Explore Liba’s advanced learning opportunities and educational programs tailored to enhance your skills and knowledge.",
    keywords: "Liba, advanced learning, educational programs"
  },
  {
    path: "/University/ClarkUniversity",
    name: "Clark University",
    element: ClarkUniversity,
    exact: true,
    title: "Clark University - Academic Excellence and Innovation",
    description: "Discover Clark University’s commitment to academic excellence and innovative educational programs.",
    keywords: "Clark University, academic excellence, innovative education"
  },
  {
    path: "/University/InternationalUniversity",
    name: "International University",
    element: InternationalUniversity,
    exact: true,
    title: "International University - Global Education Opportunities",
    description: "Find out more about International University and its global education opportunities for students worldwide.",
    keywords: "International University, global education, international programs"
  },
  {
    path: "/University/ESDESBusinessSchool",
    name: "ESDES Business School",
    element: ESDESBusinessSchool,
    exact: true,
    title: "ESDES Business School - Business Education Excellence",
    description: "Explore ESDES Business School’s programs focused on delivering excellence in business education.",
    keywords: "ESDES Business School, business education, excellence"
  },
  {
    path: "/University/Aivancity",
    name: "Aivancity",
    element: Aivancity,
    exact: true,
    title: "Aivancity - Cutting-Edge Educational Programs",
    description: "Learn about Aivancity and its cutting-edge educational programs designed to advance your career.",
    keywords: "Aivancity, cutting-edge education, career advancement"
  },
  {
    path: "/University/NortheasternUniversity",
    name: "Northeastern University",
    element: NortheasternUniversity,
    exact: true,
    title: "Northeastern University - Comprehensive Academic Programs",
    description: "Discover Northeastern University’s comprehensive academic programs and its impact on student success.",
    keywords: "Northeastern University, academic programs, student success"
  },
  {
    path: "/University/HaagaHeliaUniversity",
    name: "Haaga Helia University",
    element: HaagaHeliaUniversity,
    exact: true,
    title: "Haaga Helia University - Leading Business Education",
    description: "Find out more about Haaga Helia University and its leading business education programs.",
    keywords: "Haaga Helia University, business education, leading programs"
  },
  {
    path: "/University/VaasaUniversity",
    name: "Vaasa University",
    element: VaasaUniversity,
    exact: true,
    title: "Vaasa University - Quality Higher Education",
    description: "Explore Vaasa University’s commitment to providing high-quality higher education across various disciplines.",
    keywords: "Vaasa University, quality education, higher education"
  },
  {
    path: "/University/MetropoliaUniversity",
    name: "Metropolia University",
    element: MetropoliaUniversity,
    exact: true,
    title: "Metropolia University - Innovative Learning Solutions",
    description: "Get information about Metropolia University and its innovative learning solutions designed for modern students.",
    keywords: "Metropolia University, innovative learning, modern education"
  },
  {
    path: "/University/SeinajokiUniversity",
    name: "Seinajoki University",
    element: SeinajokiUniversity,
    exact: true,
    title: "Seinajoki University - Diverse Academic Programs",
    description: "Learn about Seinajoki University’s diverse academic programs and its approach to higher education.",
    keywords: "Seinajoki University, academic programs, higher education"
  },
  {
    path: "/University/InternationalBusinessUniversity",
    name: "International Business University",
    element: InternationalBusinessUniversity,
    exact: true,
    title: "International Business University - Global Business Education",
    description: "Discover International Business University’s global business education programs and its impact on the international business landscape.",
    keywords: "International Business University, global business, education programs"
  },
  {
    path: "/University/ColoradoStateUniversity",
    name: "Colorado State University",
    element: ColoradoStateUniversity,
    exact: true,
    title: "Colorado State University - Premier Higher Education",
    description: "Find detailed information about Colorado State University and its premier higher education programs.",
    keywords: "Colorado State University, higher education, academic programs"
  },
  {
    path: "/hcm/liba",
    name: "University Courses",
    element: UniversityCourses,
    exact: true,
    title: "University Courses - Explore a Range of Programs",
    description: "Browse a variety of university courses available through My App, tailored to meet diverse educational needs.",
    keywords: "university courses, program listings, educational opportunities"
  },
  {
    path: "/PGDM/liba",
    name: "PGDM",
    element: PGDM,
    exact: true,
    title: "PGDM - Comprehensive Management Programs",
    description: "Learn about the PGDM programs offered by our partner institutions and how they can advance your management career.",
    keywords: "PGDM, management programs, career advancement"
  },
  {
    path: "/BDU",
    name: "BDU",
    element: BDU,
    exact: true,
    title: "BDU - Academic Excellence and Opportunities",
    description: "Explore BDU and its academic offerings that provide excellence in education and various opportunities for students.",
    keywords: "BDU, academic excellence, student opportunities"
  },
  {
    path: "/AboutusPageSample",
    name: "About Us Page Sample",
    element: AboutusPageSample,
    exact: true,
    title: "About Us Page Sample - Preview Our Company Information",
    description: "View a sample of our About Us page to understand how we present company information and engage with our audience.",
    keywords: "about us page, company information, sample preview"
  },

  {
    path: "*",
    name: "NotFound",
    element: NotFound,
    exact: false, // No need for exact match for 404 route
    title: "404 - Page Not Found",
    description: "This page does not exist.",
    keywords: "404, not found, error",
  },
  {
    path: "/Online_MBA_Human_Resource_Management",
    name: "Online MBA in Human Resource Management",
    element: Online_MBA_Human_Resource_Management,
    exact: true,
    title: "Online MBA in Human Resource Management - Enhance Your HR Skills",
    description: "Explore our Online MBA in Human Resource Management to develop essential HR competencies and leadership skills.",
    keywords: "MBA, Human Resource Management, online course"
  },
  {
    path: "/Online_MBA_Finance",
    name: "Online MBA in Finance",
    element: Online_MBA_Finance,
    exact: true,
    title: "Online MBA in Finance - Master Financial Strategies",
    description: "Gain expertise in finance with our Online MBA program, focusing on investment strategies and financial analysis.",
    keywords: "MBA, Finance, online course"
  },
  {
    path: "/Online_MBA_Marketing",
    name: "Online MBA in Marketing",
    element: Online_MBA_Marketing,
    exact: true,
    title: "Online MBA in Marketing - Drive Business Growth",
    description: "Elevate your marketing skills with our Online MBA, covering digital marketing, consumer behavior, and more.",
    keywords: "MBA, Marketing, online course"
  },
  {
    path: "/Online_MBA_IT_FinTech",
    name: "Online MBA in IT FinTech",
    element: Online_MBA_IT_FinTech,
    exact: true,
    title: "Online MBA in IT FinTech - Innovate Financial Solutions",
    description: "Learn how to merge technology and finance in our Online MBA in IT FinTech program.",
    keywords: "MBA, IT, FinTech, online course"
  },
  {
    path: "/Online_MBA_Operations_Management",
    name: "Online MBA in Operations Management",
    element: Online_MBA_Operations_Management,
    exact: true,
    title: "Online MBA in Operations Management - Optimize Business Processes",
    description: "Enhance your operations management skills with our comprehensive online MBA program.",
    keywords: "MBA, Operations Management, online course"
  },
  {
    path: "/Online_MBA_Analytics_and_Data_Science",
    name: "Online MBA in Analytics and Data Science",
    element: Online_MBA_Analytics_and_Data_Science,
    exact: true,
    title: "Online MBA in Analytics and Data Science - Data-Driven Decisions",
    description: "Master data analytics and decision-making with our Online MBA program focused on data science.",
    keywords: "MBA, Data Science, Analytics, online course"
  },
  {
    path: "/Online_MBA_Information_System_Management",
    name: "Online MBA in Information System Management",
    element: Online_MBA_Information_System_Management,
    exact: true,
    title: "Online MBA in Information System Management - Managing IT Resources",
    description: "Learn to manage information systems effectively with our Online MBA program.",
    keywords: "MBA, Information Systems, online course"
  },
  {
    path: "/Online_MBA_Project_Management",
    name: "Online MBA in Project Management",
    element: Online_MBA_Project_Management,
    exact: true,
    title: "Online MBA in Project Management - Lead Successful Projects",
    description: "Acquire project management skills to lead teams and projects successfully in our Online MBA program.",
    keywords: "MBA, Project Management, online course"
  },
  {
    path: "/Bachelor_of_Computer_Applications_Online_BCA",
    name: "Bachelor of Computer Applications (Online BCA)",
    element: Bachelor_of_Computer_Applications_Online_BCA,
    exact: true,
    title: "Bachelor of Computer Applications (Online BCA) - Start Your IT Career",
    description: "Kickstart your career in IT with our Online Bachelor of Computer Applications program.",
    keywords: "BCA, Computer Applications, online course"
  },
  {
    path: "/Bachelor_of_Business_Administration_BBA",
    name: "Bachelor of Business Administration (BBA)",
    element: Bachelor_of_Business_Administration_BBA,
    exact: true,
    title: "Bachelor of Business Administration (BBA) - Build Your Business Acumen",
    description: "Learn the fundamentals of business with our Bachelor of Business Administration program.",
    keywords: "BBA, Business Administration, online course"
  },
  {
    path: "/Bachelor_of_Commerce_BCOM",
    name: "Bachelor of Commerce (BCOM)",
    element: Bachelor_of_Commerce_BCOM,
    exact: true,
    title: "Bachelor of Commerce (BCOM) - Understand Commerce and Finance",
    description: "Get a strong foundation in commerce with our Bachelor of Commerce program.",
    keywords: "BCOM, Commerce, online course"
  },
  {
    path: "/Master_of_Computer_Applications_MCA",
    name: "Master of Computer Applications (MCA)",
    element: Master_of_Computer_Applications_MCA,
    exact: true,
    title: "Master of Computer Applications (MCA) - Advance Your IT Skills",
    description: "Enhance your IT expertise with our Master of Computer Applications program.",
    keywords: "MCA, Computer Applications, online course"
  },
  {
    path: "/Master_of_Commerce_MCOM",
    name: "Master of Commerce (MCOM)",
    element: Master_of_Commerce_MCOM,
    exact: true,
    title: "Master of Commerce (MCOM) - Deepen Your Knowledge of Commerce",
    description: "Advance your understanding of commerce with our Master of Commerce program.",
    keywords: "MCOM, Commerce, online course"
  },
  {
    path: "/MBA_Finance",
    name: "MBA in Finance",
    element: MBA_Finance,
    exact: true,
    title: "MBA in Finance - Master Financial Management",
    description: "Specialize in finance with our comprehensive MBA program focused on financial management.",
    keywords: "MBA, Finance, offline course"
  },
  {
    path: "/MBA_Human_Resource_Management",
    name: "MBA in Human Resource Management",
    element: MBA_Human_Resource_Management,
    exact: true,
    title: "MBA in Human Resource Management - Develop HR Leaders",
    description: "Become a leader in human resources with our MBA program focused on HR management.",
    keywords: "MBA, HR, offline course"
  },
  {
    path: "/MBA_General_Management",
    name: "MBA in General Management",
    element: MBA_General_Management,
    exact: true,
    title: "MBA in General Management - Master Business Management",
    description: "Gain a broad understanding of business with our MBA in General Management.",
    keywords: "MBA, General Management, offline course"
  },
  {
    path: "/MBA_Marketing",
    name: "MBA in Marketing",
    element: MBA_Marketing,
    exact: true,
    title: "MBA in Marketing - Strategic Marketing Skills",
    description: "Enhance your marketing strategy skills with our MBA program.",
    keywords: "MBA, Marketing, offline course"
  },
  {
    path: "/MBA_Finance_and_Marketing",
    name: "MBA in Finance and Marketing",
    element: MBA_Finance_and_Marketing,
    exact: true,
    title: "MBA in Finance and Marketing - Dual Specialization",
    description: "Combine finance and marketing expertise with our dual specialization MBA.",
    keywords: "MBA, Finance, Marketing, offline course"
  },
  {
    path: "/MBA_Human_Resource_Management_and_Finance",
    name: "MBA in Human Resource Management and Finance",
    element: MBA_Human_Resource_Management_and_Finance,
    exact: true,
    title: "MBA in HR Management and Finance - Integrate HR with Finance",
    description: "Learn to integrate HR practices with financial strategies in this MBA program.",
    keywords: "MBA, HR, Finance, offline course"
  },
  {
    path: "/MBA_Marketing_and_Human_Resource_Management",
    name: "MBA in Marketing and Human Resource Management",
    element: MBA_Marketing_and_Human_Resource_Management,
    exact: true,
    title: "MBA in Marketing and HR Management - Cross-Discipline Skills",
    description: "Gain skills in both marketing and HR management with our dual MBA program.",
    keywords: "MBA, Marketing, HR, offline course"
  },
  {
    path: "/MBA_Project_Management",
    name: "MBA in Project Management",
    element: MBA_Project_Management,
    exact: true,
    title: "MBA in Project Management - Lead Projects Effectively",
    description: "Develop your project management skills with our focused MBA program.",
    keywords: "MBA, Project Management, offline course"
  },
  {
    path: "/MBA_Logistics_Supply_Chain_Management",
    name: "MBA in Logistics and Supply Chain Management",
    element: MBA_Logistics_Supply_Chain_Management,
    exact: true,
    title: "MBA in Logistics and Supply Chain Management - Optimize Supply Chains",
    description: "Master logistics and supply chain management with our MBA program.",
    keywords: "MBA, Logistics, Supply Chain, offline course"
  },
  {
    path: "/MBA_Healthcare_Management",
    name: "MBA in Healthcare Management",
    element: MBA_Healthcare_Management,
    exact: true,
    title: "MBA in Healthcare Management - Transform Healthcare Systems",
    description: "Learn to manage healthcare organizations effectively with our MBA program.",
    keywords: "MBA, Healthcare, Management, offline course"
  },
  {
    path: "/MBA_Information_Technology_Management",
    name: "MBA in Information Technology Management",
    element: MBA_Information_Technology_Management,
    exact: true,
    title: "MBA in IT Management - Manage Technology Resources",
    description: "Develop skills to manage information technology in organizations with our MBA.",
    keywords: "MBA, IT, Management, offline course"
  },
  {
    path: "/MBA_Business_Intelligence_Analytics",
    name: "MBA in Business Intelligence and Analytics",
    element: MBA_Business_Intelligence_Analytics,
    exact: true,
    title: "MBA in Business Intelligence and Analytics - Data-Driven Decisions",
    description: "Learn business intelligence techniques to drive decisions with our MBA program.",
    keywords: "MBA, Business Intelligence, Analytics, offline course"
  },
  {
    path: "/MBA_International_Finance_Accredited_by_ACCA_UK",
    name: "MBA in International Finance (Accredited by ACCA UK)",
    element: MBA_International_Finance_Accredited_by_ACCA_UK,
    exact: true,
    title: "MBA in International Finance - Global Financial Strategies",
    description: "Specialize in international finance with our ACCA-accredited MBA program.",
    keywords: "MBA, International Finance, ACCA, offline course"
  },
  {
    path: "/MBA_Entrepreneurship_and_Leadership",
    name: "MBA in Entrepreneurship and Leadership",
    element: MBA_Entrepreneurship_and_Leadership,
    exact: true,
    title: "MBA in Entrepreneurship and Leadership - Build Innovative Leaders",
    description: "Foster entrepreneurial skills and leadership qualities with our MBA program.",
    keywords: "MBA, Entrepreneurship, Leadership, offline course"
  },
  {
    path: "/MBA_International_Business_Management",
    name: "MBA in International Business Management",
    element: MBA_International_Business_Management,
    exact: true,
    title: "MBA in International Business Management - Global Business Skills",
    description: "Gain insights into global business management with our MBA program.",
    keywords: "MBA, International Business, offline course"
  },
  {
    path: "/MA_Economics",
    name: "MA in Economics",
    element: MA_Economics,
    exact: true,
    title: "MA in Economics - Analyze Economic Trends",
    description: "Study economics deeply with our Master of Arts in Economics program.",
    keywords: "MA, Economics, offline course"
  },
  {
    path: "/Master_of_Business_Administration_MBA",
    name: "Master of Business Administration (MBA)",
    element: Master_of_Business_Administration_MBA,
    exact: true,
    title: "Master of Business Administration (MBA) - Lead with Confidence",
    description: "Pursue our MBA program to enhance your leadership and management skills.",
    keywords: "MBA, Business Administration, offline course"
  },
  {
    path: "/MA_Public_Administration",
    name: "MA in Public Administration",
    element: MA_Public_Administration,
    exact: true,
    title: "MA in Public Administration - Serve the Public Effectively",
    description: "Learn the intricacies of public administration with our MA program.",
    keywords: "MA, Public Administration, offline course"
  },
  {
    path: "/MA_Political_Science",
    name: "MA in Political Science",
    element: MA_Political_Science,
    exact: true,
    title: "MA in Political Science - Understand Political Systems",
    description: "Study the political landscape with our Master of Arts in Political Science.",
    keywords: "MA, Political Science, offline course"
  },
  {
    path: "/MA_English",
    name: "MA in English",
    element: MA_English,
    exact: true,
    title: "MA in English - Explore Literature and Linguistics",
    description: "Dive into the world of English literature and linguistics with our MA program.",
    keywords: "MA, English, offline course"
  },
  {
    path: "/MA_History",
    name: "MA in History",
    element: MA_History,
    exact: true,
    title: "MA in History - Investigate Historical Events",
    description: "Explore significant historical events and their impact with our MA in History.",
    keywords: "MA, History, offline course"
  },
  {
    path: "/BBA_Batchelor_of_Business_Administration",
    name: "BBA (Bachelor of Business Administration)",
    element: BBA_Batchelor_of_Business_Administration,
    exact: true,
    title: "BBA (Bachelor of Business Administration) - Foundation in Business",
    description: "Start your business education with our Bachelor of Business Administration program.",
    keywords: "BBA, Business Administration, offline course"
  },
  {
    path: "/BA_English",
    name: "BA in English",
    element: BA_English,
    exact: true,
    title: "BA in English - Study Language and Literature",
    description: "Gain a solid foundation in English language and literature with our BA program.",
    keywords: "BA, English, offline course"
  },
  {
    path: "/MBA_Master_of_Business_Administration",
    name: "MBA (Master of Business Administration)",
    element: MBA_Master_of_Business_Administration,
    exact: true,
    title: "MBA (Master of Business Administration) - Advance Your Career",
    description: "Pursue your career goals with our Master of Business Administration program.",
    keywords: "MBA, Business Administration, offline course"
  },
  {
    path: "/MCA_Master_of_Computer_Application",
    name: "MCA (Master of Computer Applications)",
    element: MCA_Master_of_Computer_Application,
    exact: true,
    title: "MCA (Master of Computer Applications) - Become an IT Expert",
    description: "Gain advanced skills in computer applications with our MCA program.",
    keywords: "MCA, Computer Applications, offline course"
  },
  {
    path: "/B_Com_Bachelor_of_Commerce",
    name: "B.Com (Bachelor of Commerce)",
    element: B_Com_Bachelor_of_Commerce,
    exact: true,
    title: "B.Com (Bachelor of Commerce) - Foundations in Commerce",
    description: "Learn the basics of commerce with our Bachelor of Commerce program.",
    keywords: "B.Com, Commerce, offline course"
  },
  {
    path: "/MA_Sociology_Master_of_Arts",
    name: "MA in Sociology",
    element: MA_Sociology_Master_of_Arts,
    exact: true,
    title: "MA in Sociology - Understand Social Structures",
    description: "Explore societal structures and issues with our Master of Arts in Sociology.",
    keywords: "MA, Sociology, offline course"
  },
  {
    path: "/MS_in_Data_Analytics",
    name: "MS in Data Analytics",
    element: MS_in_Data_Analytics,
    exact: true,
    title: "MS in Data Analytics - Data-Driven Decision Making",
    description: "Specialize in data analytics with our Master's program.",
    keywords: "MS, Data Analytics, offline course"
  },
  {
    path: "/MS_in_Data_Analytics_online",
    name: "MS in Data Analytics (Online)",
    element: MS_in_Data_Analytics_online,
    exact: true,
    title: "MS in Data Analytics (Online) - Learn at Your Own Pace",
    description: "Pursue your Master's in Data Analytics online with flexible learning options.",
    keywords: "MS, Data Analytics, online course"
  },
  {
    path: "/Master_in_Business_Administration_online",
    name: "Master in Business Administration (Online)",
    element: Master_in_Business_Administration_online,
    exact: true,
    title: "Master in Business Administration (Online) - Flexible Learning",
    description: "Advance your career with our Online Master in Business Administration program.",
    keywords: "MBA, Business Administration, online course"
  },
  {
    path: "/MS_in_Information_Technology_online",
    name: "MS in Information Technology (Online)",
    element: MS_in_Information_Technology_online,
    exact: true,
    title: "MS in Information Technology (Online) - IT Skills for the Future",
    description: "Gain essential IT skills with our online Master's program.",
    keywords: "MS, Information Technology, online course"
  },
  {
    path: "/MS_in_Project_Management_online",
    name: "MS in Project Management (Online)",
    element: MS_in_Project_Management_online,
    exact: true,
    title: "MS in Project Management (Online) - Manage Projects Remotely",
    description: "Learn project management online and manage teams effectively.",
    keywords: "MS, Project Management, online course"
  },
  {
    path: "/MS_in_Computer_Science_online",
    name: "MS in Computer Science (Online)",
    element: MS_in_Computer_Science_online,
    exact: true,
    title: "MS in Computer Science (Online) - Flexible IT Learning",
    description: "Enhance your computer science knowledge with our online program.",
    keywords: "MS, Computer Science, online course"
  },
  {
    path: "/Master_in_Business_Administration_offline",
    name: "Master in Business Administration (Offline)",
    element: Master_in_Business_Administration_offline,
    exact: true,
    title: "Master in Business Administration (Offline) - Hands-On Learning",
    description: "Experience a comprehensive MBA education with our offline program.",
    keywords: "MBA, Business Administration, offline course"
  },
  {
    path: "/MS_Data_Analytics",
    name: "MS in Data Analytics",
    element: MS_in_Data_Analytics_offline,
    exact: true,
    title: "MS in Data Analytics - Gain In-Depth Data Insights",
    description: "Learn how to analyze and interpret complex data with our MS in Data Analytics program.",
    keywords: "MS, Data Analytics, offline course"
  },
  {
    path: "/MS_Information_Technology",
    name: "MS in Information Technology",
    element: MS_in_Information_Technology_offline,
    exact: true,
    title: "MS in Information Technology - Master IT Strategies",
    description: "Specialize in information technology with our comprehensive MS program.",
    keywords: "MS, Information Technology, offline course"
  },
  {
    path: "/MS_Project_Management",
    name: "MS in Project Management",
    element: MS_in_Project_Management_offline,
    exact: true,
    title: "MS in Project Management - Lead Successful Projects",
    description: "Learn project management principles and techniques with our MS program.",
    keywords: "MS, Project Management, offline course"
  },
  {
    path: "/MS_Computer_Science",
    name: "MS in Computer Science",
    element: MS_in_Computer_Science_offline,
    exact: true,
    title: "MS in Computer Science - Explore Advanced Computing",
    description: "Delve into advanced computing topics with our MS in Computer Science.",
    keywords: "MS, Computer Science, offline course"
  },
  {
    path: "/Masters_Business_Administration_60_ECTS_Online",
    name: "Masters in Business Administration (60 ECTS)",
    element: Masters_in_Business_Administration_60_ECTS_online,
    exact: true,
    title: "Masters in Business Administration - Accelerate Your Career",
    description: "Achieve a 60 ECTS MBA online and enhance your business acumen.",
    keywords: "Masters, Business Administration, online course"
  },
  {
    path: "/Masters_International_Management",
    name: "Masters in International Management",
    element: Masters_in_International_Management_online,
    exact: true,
    title: "Masters in International Management - Global Business Strategies",
    description: "Learn global business strategies with our International Management program.",
    keywords: "Masters, International Management, online course"
  },
  {
    path: "/Masters_Computer_Science_120_ECTS_Online",
    name: "Masters in Computer Science (120 ECTS)",
    element: Masters_in_Computer_science_120_ECTS_online,
    exact: true,
    title: "Masters in Computer Science - Comprehensive Computing Skills",
    description: "Complete a 120 ECTS Master's in Computer Science with online learning.",
    keywords: "Masters, Computer Science, online course"
  },
  {
    path: "/Masters_Data_Science_60_ECTS_Online",
    name: "Masters in Data Science (60 ECTS)",
    element: Masters_in_Data_Science_60_ECTS_online,
    exact: true,
    title: "Masters in Data Science - Unlock the Power of Data",
    description: "Gain expertise in data science with our 60 ECTS online master's program.",
    keywords: "Masters, Data Science, online course"
  },
  {
    path: "/Masters_Data_Science_60_ECTS_Offline",
    name: "Masters in Data Science (60 ECTS) Offline",
    element: Masters_in_Data_Science_60_ECTS_Offline,
    exact: true,
    title: "Masters in Data Science - In-Person Learning Experience",
    description: "Engage in a hands-on data science program with our offline master's degree.",
    keywords: "Masters, Data Science, offline course"
  },
  {
    path: "/Masters_Computer_Science_120_ECTS_Offline",
    name: "Masters in Computer Science (120 ECTS) Offline",
    element: Masters_in_Computer_science_120_ECTS_Offline,
    exact: true,
    title: "Masters in Computer Science - Offline Degree Program",
    description: "Pursue a comprehensive computer science education with in-person classes.",
    keywords: "Masters, Computer Science, offline course"
  },
  {
    path: "/Masters_International_Management_Offline",
    name: "Masters in International Management Offline",
    element: Masters_in_International_Management_Offline,
    exact: true,
    title: "Masters in International Management - Study Abroad Opportunities",
    description: "Immerse yourself in international management with our offline program.",
    keywords: "Masters, International Management, offline course"
  },
  {
    path: "/Masters_Business_Administration_60_ECTS_Offline",
    name: "Masters in Business Administration (60 ECTS) Offline",
    element: Masters_in_Business_Administration_60_ECTS_Offline,
    exact: true,
    title: "Masters in Business Administration - Traditional Learning",
    description: "Experience traditional learning in business administration with our 60 ECTS program.",
    keywords: "Masters, Business Administration, offline course"
  },
  {
    path: "/Masters_Business_Administration_90_ECTS_Offline",
    name: "Masters in Business Administration (90 ECTS) Offline",
    element: Masters_in_Business_Administration_90_ECTS_Offline,
    exact: true,
    title: "Masters in Business Administration - Advanced Studies",
    description: "Deepen your business knowledge with our 90 ECTS offline MBA program.",
    keywords: "Masters, Business Administration, offline course"
  },
  {
    path: "/Msc_Sustainable_Tourism_Event_Management_Online",
    name: "Msc in Sustainable Tourism and Event Management",
    element: Msc_Sustainable_Tourism_Event_Management_Online,
    exact: true,
    title: "Msc in Sustainable Tourism - Manage Events Sustainably",
    description: "Learn how to manage sustainable tourism and events with our Msc program.",
    keywords: "Msc, Sustainable Tourism, online course"
  },
  {
    path: "/Msc_Circular_Economy_Sustainable_Innovation_Online",
    name: "Msc in Circular Economy and Sustainable Innovation",
    element: Msc_in_Circular_Economy_Sustainable_Innovation_Online,
    exact: true,
    title: "Msc in Circular Economy - Drive Sustainable Change",
    description: "Explore sustainable innovation in the circular economy with our Msc program.",
    keywords: "Msc, Circular Economy, online course"
  },
  {
    path: "/Msc_Impact_Finance_Fintech_Management_Online",
    name: "Msc in Impact Finance and Fintech Management",
    element: Msc_in_Impact_Finance_Fintech_Management_Online,
    exact: true,
    title: "Msc in Impact Finance - Transform Financial Solutions",
    description: "Specialize in fintech management and impact finance with our Msc program.",
    keywords: "Msc, Impact Finance, online course"
  },
  {
    path: "/Msc_Impact_Finance_Fintech_Management_Offline",
    name: "Msc in Impact Finance and Fintech Management Offline",
    element: Msc_in_Impact_Finance_Fintech_Management_Offline,
    exact: true,
    title: "Msc in Impact Finance - In-Person Learning",
    description: "Engage in in-person learning for fintech management and impact finance.",
    keywords: "Msc, Impact Finance, offline course"
  },
  {
    path: "/Msc_Circular_Economy_Sustainable_Innovation_Offline",
    name: "Msc in Circular Economy and Sustainable Innovation Offline",
    element: Msc_in_Circular_Economy_Sustainable_Innovation_Offline,
    exact: true,
    title: "Msc in Circular Economy - Hands-On Learning",
    description: "Participate in hands-on learning for sustainable innovation.",
    keywords: "Msc, Circular Economy, offline course"
  },
  {
    path: "/Msc_Sustainable_Tourism_Event_Management_Offline",
    name: "Msc in Sustainable Tourism and Event Management Offline",
    element: Msc_Sustainable_Tourism_Event_Management_Offline,
    exact: true,
    title: "Msc in Sustainable Tourism - Offline Program",
    description: "Experience offline learning for sustainable tourism management.",
    keywords: "Msc, Sustainable Tourism, offline course"
  },
  {
    path: "/Msc_Data_Engineering_Online",
    name: "Msc in Data Engineering Online",
    element: Msc_in_Data_Engineering_Online,
    exact: true,
    title: "Msc in Data Engineering - Online Program",
    description: "Master data engineering techniques with our online Msc program.",
    keywords: "Msc, Data Engineering, online course"
  },
  {
    path: "/Msc_Data_Engineering_Offline",
    name: "Msc in Data Engineering Offline",
    element: Msc_in_Data_Engineering_offline,
    exact: true,
    title: "Msc in Data Engineering - Offline Program",
    description: "Engage in offline learning for data engineering skills.",
    keywords: "Msc, Data Engineering, offline course"
  },
  {
    path: "/MS_Project_Management_NEU_Canada_IIIT_B_Toronto_Online",
    name: "MS in Project Management (NEU Canada IIIT B Toronto)",
    element: MS_in_Project_Management_NEU_Canada_IIIT_B_Toronto_Online,
    exact: true,
    title: "MS in Project Management - NEU Canada IIIT B Toronto",
    description: "Specialize in project management with an international perspective.",
    keywords: "MS, Project Management, online course"
  },
  {
    path: "/MSPM_Canada_Track_IMT_Ghaziabad_Toronto_Online",
    name: "MSPM Canada Track (IMT Ghaziabad Toronto)",
    element: MSPM_Canada_Track_IMT_Ghaziabad_Toronto_Online,
    exact: true,
    title: "MSPM Canada Track - Innovative Project Management",
    description: "Discover innovative project management techniques with our Canada Track program.",
    keywords: "MSPM, Project Management, online course"
  },
  {
    path: "/MPS_in_Informatics_Toronto_Online",
    name: "MPS in Informatics Toronto",
    element: MPS_in_Informatics_Toronto_Online,
    exact: true,
    title: "MPS in Informatics - Toronto Program",
    description: "Pursue informatics in a vibrant urban setting with our MPS program.",
    keywords: "MPS, Informatics, online course"
  },
  {
    path: "/MBA_Technology_Innovation_Entrepreneurship_Offline",
    name: "MBA in Technology, Innovation, and Entrepreneurship Offline",
    element: MBA_in_Technology_Innovation_and_Entrepreneurship_Offline,
    exact: true,
    title: "MBA in Technology, Innovation, and Entrepreneurship - Offline Program",
    description: "Explore technology and innovation in business with our offline MBA.",
    keywords: "MBA, Technology, offline course"
  },
  {
    path: "/MBA_Technology_Innovation_Entrepreneurship_Online",
    name: "MBA in Technology, Innovation, and Entrepreneurship Online",
    element: MBA_in_Technology_Innovation_and_Entrepreneurship_Online,
    exact: true,
    title: "MBA in Technology, Innovation, and Entrepreneurship - Online Program",
    description: "Enhance your business skills with our online MBA in technology and innovation.",
    keywords: "MBA, Technology, online course"
  },
  {
    path: "/MBA_Business_Technologies_Online",
    name: "MBA in Business Technologies Online",
    element: MBA_Business_Technologies_Online,
    exact: true,
    title: "MBA in Business Technologies - Online Learning",
    description: "Master business technologies with our online MBA program.",
    keywords: "MBA, Business Technologies, online course"
  },
  {
    path: "/MBA_Leading_Business_Transformation_Online",
    name: "MBA in Leading Business Transformation Online",
    element: MBA_Leading_Business_Transformation_Online,
    exact: true,
    title: "MBA in Leading Business Transformation - Online Course",
    description: "Lead transformation in organizations with our online MBA program.",
    keywords: "MBA, Business Transformation, online course"
  },
  {
    path: "/MBA_International_Business_Management_Online",
    name: "MBA in International Business Management Online",
    element: MBA_International_Business_Management_Online,
    exact: true,
    title: "MBA in International Business Management - Global Perspectives",
    description: "Gain global business insights with our online MBA program.",
    keywords: "MBA, International Business, online course"
  },
  {
    path: "/MBA_Business_Informatics_Online",
    name: "MBA in Business Informatics Online",
    element: MBA_Business_Informatics_Online,
    exact: true,
    title: "MBA in Business Informatics - Online Learning",
    description: "Explore the intersection of business and IT with our online MBA.",
    keywords: "MBA, Business Informatics, online course"
  },
  {
    path: "/MBA_in_International_Business_Management_Online",
    name: "MBA in International Business Management Online",
    element: MBA_in_International_Business_Management_Online,
    exact: true,
    title: "MBA in International Business Management - Comprehensive Program",
    description: "Pursue international business management with our comprehensive online MBA.",
    keywords: "MBA, International Business, online course"
  },
  {
    path: "/MBA_in_International_Business_Management_Offline",
    name: "MBA in International Business Management Offline",
    element: MBA_in_International_Business_Management_Offline,
    exact: true,
    title: "MBA in International Business Management - In-Person Learning",
    description: "Experience in-person learning for international business management.",
    keywords: "MBA, International Business, offline course"
  },
  {
    path: "/MBA_Business_Informatics_Offline",
    name: "MBA in Business Informatics Offline",
    element: MBA_Business_Informatics_Offline,
    exact: true,
    title: "MBA in Business Informatics - Offline Program",
    description: "Explore business informatics with our offline MBA program.",
    keywords: "MBA, Business Informatics, offline course"
  },
  {
    path: "/MBA_International_Business_Management_Offline",
    name: "MBA in International Business Management Offline",
    element: MBA_International_Business_Management_Offline,
    exact: true,
    title: "MBA in International Business Management - Offline Learning",
    description: "Engage in offline learning for international business management.",
    keywords: "MBA, International Business, offline course"
  },
  {
    path: "/MBA_Leading_Business_Transformation_Offline",
    name: "MBA in Leading Business Transformation Offline",
    element: MBA_Leading_Business_Transformation_Offline,
    exact: true,
    title: "MBA in Leading Business Transformation - Offline Program",
    description: "Lead business transformation with our offline MBA program.",
    keywords: "MBA, Business Transformation, offline course"
  },
  {
    path: "/MBA_Business_Technologies_Offline",
    name: "MBA in Business Technologies Offline",
    element: MBA_Business_Technologies_Offline,
    exact: true,
    title: "MBA in Business Technologies - In-Person Learning",
    description: "Master business technologies with our offline MBA program.",
    keywords: "MBA, Business Technologies, offline course"
  },
  {
    path: "/MS_Computer_Information_Systems_Online",
    name: "MS in Computer Information Systems Online",
    element: MS_Computer_Information_Systems_Online,
    exact: true,
    title: "MS in Computer Information Systems - Online Program",
    description: "Specialize in computer information systems with our online MS program.",
    keywords: "MS, Computer Information Systems, online course"
  },
  {
    path: "/MS_Applied_Statistics_in_Data_Science_Online",
    name: "MS in Applied Statistics in Data Science Online",
    element: MS_Applied_Statistics_in_Data_Science_Online,
    exact: true,
    title: "MS in Applied Statistics in Data Science - Online Course",
    description: "Learn applied statistics in data science with our MS program.",
    keywords: "MS, Applied Statistics, online course"
  }
];

export default routes;
