import React, { useEffect, useState } from "react";
import './admissions.css'
import Assets from "../../assets/assets";
import Whatapps from '../../assets/bot/whatapp.svg'
import axios from "axios";
import { FiPhoneCall } from "react-icons/fi";
const Admissions_DBA = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        careerInterest: ""
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate Full Name
        if (!formData.fullName) {
            isValid = false;
            formErrors["fullName"] = "Please enter your full name.";
        }

        // Validate Email
        if (!formData.email) {
            isValid = false;
            formErrors["email"] = "Please enter your email address.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            isValid = false;
            formErrors["email"] = "Email address is invalid.";
        }

        // Validate Phone Number
        if (!formData.phone) {
            isValid = false;
            formErrors["phone"] = "Please enter your phone number.";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            isValid = false;
            formErrors["phone"] = "Phone number is invalid. Must be 10 digits.";
        }

        // Validate Career Interest
        if (!formData.careerInterest) {
            isValid = false;
            formErrors["careerInterest"] = "Please enter your career interest.";
        }

        setErrors(formErrors);
        return isValid;
    };


    const handleScroll = () => {
        const target = document.getElementById('scrollTarget');
        target.scrollIntoView({ behavior: 'smooth' });
    };

    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }

    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        // console.log(existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            setFormData({
                fullName: "",
                email: "",
                phone: "",
                careerInterest: ""
            })
            setLoading(false);
            return;
        } else {

            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/j87gms00216fmp2o/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: "WEB",
                    UF_CRM_1637687988896: "2351", // Adding source as source description

                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {
                    // hideLoader();
                    setResponseMessage("Form submitted successfully!");
                    setFormData({
                        fullName: "",
                        email: "",
                        phone: "",
                        careerInterest: ""
                    })
                    setLoading(false);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true);

            const url = "https://api.123admissions.com/api/v1/formall";

            let data = new FormData();
            data.append("name", formData.fullName);
            data.append("email", formData.email);
            data.append("contact", formData.phone);
            data.append("cou", formData.careerInterest);
            data.append("id", 'uma');

            try {
                const response = await fetch(url, {
                    method: "POST",
                    body: data
                });

                const result = await response.json();

                if (response.ok) {

                } else {
                    setResponseMessage(`Error: ${result.message}`);
                }

            } catch (error) {
                setResponseMessage("Failed to submit the form. Please try again.");
                console.error("Error:", error);
            } finally {
                // setLoading(false);
                addLeadToBitrix24(formData.fullName, formData?.email, formData.phone)
            }
        }
    };

    const [openSections, setOpenSections] = useState([false, false, false, false]);

    const toggleSection = (index) => {
        setOpenSections(prevSections =>
            prevSections.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
    };
 
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="headers">
                        <h3>Check our latest programme.</h3>
                        <button onClick={handleScroll}>
                            ENQUIRE NOW →
                        </button>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="bodys">
                        <div className="row">
                            <div className="col-lg-7">
                                <img src={Assets.Logo} className="logo"></img>
                                <h3>Doctor of
                                    Business
                                    Administration(PHD)</h3>
                                {/* <h5>Learn Smart with</h5> */}
                                <h5>India’s Most Trusted </h5>
                                <h5> Ed-Tech Platform, 123Admissions</h5>
                                <div className="underline">
                                </div>
                                <button>Learn from Anywhere</button>
                            </div>
                            <div className="col-lg-5" >
                                <div className="campaignimg">
                                    <img src={Assets?.campaignimg} style={{ width: "70%" }}></img>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-12" id="scrollTarget">
                    <div className="enquery">
                        <div className="text">
                            <h5>Let's Connect</h5>
                        </div>
                        <div className="form-outer"  >
                            <div className="form">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="text">
                                                <h5>Kindly fill in the details so our experts can reach out to you.</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                className="form-control"
                                                name="fullName"
                                                value={formData.fullName}
                                                onChange={handleInputChange}
                                                placeholder="Enter Your Full Name"
                                            />
                                            {errors.fullName && <small className="text-danger">{errors.fullName}</small>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                className="form-control"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="Enter Your Email Address"
                                            />
                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                className="form-control"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                placeholder="Enter Your Phone Number"
                                            />
                                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <input
                                                className="form-control"
                                                name="careerInterest"
                                                value={formData.careerInterest}
                                                onChange={handleInputChange}
                                                placeholder="What would you like to pursue for career growth?"
                                            />
                                            {errors.careerInterest && <small className="text-danger">{errors.careerInterest}</small>}
                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? "Submitting..." : "Enquire Now"}
                                                </button>
                                            </div>
                                            {responseMessage && <div className="mt-3 text-center">{responseMessage}</div>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="overview">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <h2>10+ Successful years of Excellence in the Ed-Tech Industry</h2>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="borders">
                                    <h1>4,00,000+</h1>
                                    <h5>Careers transformed</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="borders">
                                    <h1>45+</h1>
                                    <h5>Academic Partners</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="borders">
                                    <h1>120+</h1>
                                    <h5>Programme</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <div className="jk" >
                                    <h1>10+</h1>
                                    <h5>Immersion and Training Centers All Over India</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="text-center">
                        <h1>Why Us</h1>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="whyus">
                                <h3>Timely Assistance</h3>
                                <p className="mt-4">Our expert team is always available to provide personalized support and timely responses, help you choose and succeed in your online courses</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="whyus2">
                                <h3>Affordable EMI</h3>
                                <p className="mt-4">Study without stress and Invest in your future free of stress with our flexible financial solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="whyus">
                                <h3>On-Demand Courses</h3>
                                <p className="mt-4">Access lectures, assignments and resources at your fingertips and balance your education with your busy life.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="whyus2">
                                <h3>25+ Top Rated Universities</h3>
                                <p className="mt-4">Gain a globally recognized degree and expand your career opportunities with our prestigious university partners.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="text-center">
                        <h1>Frequently Asked Questions</h1>
                    </div>
                </div>

                <div className="col-lg-12 mt-5">
                    <div className="faq-outer">
                        <div className="faq">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="faq-q" onClick={() => toggleSection(0)}>
                                        <h5>What are the institutions partnered with 123Admissions?</h5>
                                        <small>+</small>
                                    </div>
                                    {openSections[0] && (
                                        <div className="faq-a">
                                            <p>123Admissions has partnership with a wide range of renowned institutions, including LIBA, international universities, Golden Gate University, Bharathidasan University, Andhra University, DY Patil University and many more. These partnerships allow students to access high-quality online degree programme.</p>
                                        </div>)}

                                </div>
                                <div className="col-lg-12">
                                    <div className="faq-q" onClick={() => toggleSection(1)}>
                                        <h5>What are the courses available with 123Admissions?</h5>
                                        <small>+</small>
                                    </div>
                                    {openSections[1] && (<div className="faq-a">
                                        <p>123Admissions offers a wide range of online degree programme, including Bachelor's degrees and Master's degrees in high-demand specializations. These programme are designed to be accessible and affordable, making them a great option for students seeking flexible and convenient education.</p>
                                    </div>)}
                                </div>
                                <div className="col-lg-12">
                                    <div className="faq-q" onClick={() => toggleSection(2)}>
                                        <h5>Are these online degrees really credible ?</h5>
                                        <small>+</small>
                                    </div>
                                    {openSections[2] && (<div className="faq-a">
                                        <p> Yes, The University Grants Commission (UGC) in India has recognized online degrees as equivalent to offline degrees.This means that online degrees from UGC approved universities hold the same value and credibility as traditional on-campus degrees</p>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="text-center">
                        <h2>Placement Support</h2>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="placement">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="cop">
                                    <p>We have teamed up with over 300 top-tier companies across various industries to help you land your dream job. Whether you're looking for a tech startup, a multinational corporation, or a small business, we've got connections to help you explore a wide range of career paths.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cop">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h1>300+</h1>
                                            <h5>Company Partnerships</h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <h1>170+</h1>
                                            <h5>International MoUs</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="text-center">
                        <h2>Hear from our happy Students:</h2>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="review">
                        <div className="row">
                            <div className="col-lg-6 mt-3">
                                <div className="s_review">
                                    <div className="s_review_h">
                                        <div className="cricle">
                                            S
                                        </div>
                                        <div className="star">
                                            <h5>Surendar</h5>
                                            <h5 className="gh">★★★★★</h5>
                                        </div>

                                    </div>
                                    <div className="s_review_s">
                                        <p>123admissions was a game-changer in my journey to higher education. The team provided invaluable guidance and support throughout the application process. Their expertise in understanding top-tier universities and their requirements was instrumental in securing my admission to a prestigious institution. I am truly grateful for their timely assistance and personalized </p>
                                    </div>
                                    <div className="s_review_b">
                                        Dec 1, 2023
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="s_review">
                                    <div className="s_review_h">
                                        <div className="cricle">
                                            R
                                        </div>
                                        <div className="star">
                                            <h5>Rahul</h5>
                                            <h5 className="gh">★★★★★</h5>
                                        </div>

                                    </div>
                                    <div className="s_review_s">
                                        <p>123admissions was instrumental in my success. Their expert guidance helped me identify the right programme that are highly flexible. Thanks to their support, I secured a prestigious MBA and landed a dream job in healthcare administration. I highly recommend 123admissions to anyone looking to elevate their career.
                                        </p>
                                    </div>
                                    <div className="s_review_b">
                                        Jan 12, 2023
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="s_review">
                                    <div className="s_review_h">
                                        <div className="cricle">
                                            S
                                        </div>
                                        <div className="star">
                                            <h5>Sathya</h5>
                                            <h5 className="gh">★★★★★</h5>
                                        </div>

                                    </div>
                                    <div className="s_review_s">
                                        <p>123admissions' impressive network of top-tier universities gave me the confidence to choose them for my online college admissions. Thanks to their expert guidance, I successfully secured a spot in a prestigious university's online degree programme</p>
                                    </div>
                                    <div className="s_review_b">
                                        Oct 26, 2022
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                                <div className="s_review">
                                    <div className="s_review_h">
                                        <div className="cricle">
                                            DA
                                        </div>
                                        <div className="star">
                                            <h5>DEVI A</h5>
                                            <h5 className="gh">★★★★★</h5>
                                        </div>

                                    </div>
                                    <div className="s_review_s">
                                        <p>As a nurse working in Saudi Arabia, I needed a flexible, online programme to advance my career in hospital and healthcare management.They helped me find top-tier universities that offered affordable programme that fit my busy schedule. Their guidance throughout the entire process was invaluable.</p>
                                    </div>
                                    <div className="s_review_b">
                                        Jan 12, 2020
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-5">
                    <div className="floater">
                        <div className="row">
                            <div className="col-lg-6">
                                <h1>123 Admissions</h1>
                                <h3>The Number One Admissions Partner</h3>
                                <h2>Join a Community of Diverse Learners</h2>
                            </div>
                            <div className="col-lg-6">
                                <button className="whatapp">Join Us on WhatsApp</button>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <div className="text-center">
                                    <p>© 2015-2024 123admissions. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default Admissions_DBA