// src/components/AdComponent.js

import React, { useEffect } from 'react';

const AdComponent = ({ adClient, adSlot, adFormat = 'auto', adLayout = 'in-article' }) => {
  useEffect(() => {
    // Push the ad to the adsbygoogle array
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format={adFormat}
      data-ad-layout={adLayout}
    ></ins>
  );
};

export default AdComponent;
