import React, { useEffect, useState } from 'react';
import './AboutusPage.css';
import Assets from '../../assets/assets';
import Timeline from './timeline/Timeline';
import girl from '../../assets/Group 74.svg';
import Left from '../../assets/aboutus/Group 194.webp';
import Right from '../../assets/aboutus/Group 195.webp';

function AboutusPage() {
    const cardData = [
        {
            text: 'Our track record speaks for itself. We measure our success by the success of our students, and we\'re proud of the achievements they\'ve made under our guidance.',
            name: 'Lena Oceretova',
            title: 'Academic Counselor, TN',
            imgSrc: girl,
        },
        {
            text: 'We provide personalized guidance to every student, helping them achieve their goals.',
            name: 'Mark Johnson',
            title: 'Career Advisor, NY',
            imgSrc: girl,
        },
        {
            text: 'With our global partnerships, students have access to top-tier institutions around the world.',
            name: 'Emily Roberts',
            title: 'Education Specialist, CA',
            imgSrc: girl,
        },
    ];

    const [startIndex, setStartIndex] = useState(0);

    const handleRightClick = () => {
        setStartIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    };

    const handleLeftClick = () => {
        setStartIndex((prevIndex) => (prevIndex - 1 + cardData.length) % cardData.length);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const successStories = [
        {
            name: "Sarah Thompson",
            program: "Master of Business Administration (MBA)",
            university: "Harvard Business School",
            story: "I was uncertain about my future after completing my undergraduate studies, but 123Admissions provided me with the guidance and resources I needed to apply to Harvard. The team helped me refine my application and prepare for interviews, and I was thrilled to receive my acceptance letter. Now, I’m on my way to becoming a business leader, and I couldn’t have done it without the support from 123Admissions!",
        },
        {
            name: "Michael Chen",
            program: "Master of Computer Science",
            university: "Stanford University",
            story: "As a first-generation college student, I had no idea how to navigate the graduate admissions process. The advisors at 123Admissions were incredibly supportive, helping me every step of the way—from choosing the right program to writing my statement of purpose. I’m now pursuing my dream at Stanford, and I owe it all to their expertise!",
        },
        {
            name: "Aisha Patel",
            program: "Master of Public Health",
            university: "Johns Hopkins University",
            story: "123Admissions made my dream of studying public health a reality. Their team helped me identify the best programs for my career goals, and I felt confident applying with their support. I’m now learning from the best in the field at Johns Hopkins, and I’m excited to make a difference in public health policy!",
        },
        {
            name: "David Kim",
            program: "Master of Fine Arts (MFA)",
            university: "Columbia University",
            story: "Pursuing my MFA was a lifelong dream, but I didn't know how to start. The mentors at 123Admissions guided me through the process of building my portfolio and applying to Columbia. I’m now surrounded by talented artists, and I’m thrilled to develop my skills in such a creative environment!",
        },
        {
            name: "Emily Johnson",
            program: "Master of International Relations",
            university: "London School of Economics",
            story: "My passion for global issues led me to apply for a master's in International Relations, but the application process was overwhelming. Thankfully, 123Admissions provided personalized support that helped me craft a compelling application. I’m now studying at LSE and can’t wait to contribute to international diplomacy!",
        },
    ];

    return (
        <div className="aboutPage">
            {/* Header Section */}
            <div className="aboutPage__header row">
                <div className="aboutPage__header-video col-lg-6">
                    <img src={Assets?.Video} alt="About Us Video" className="aboutPage__header-img" />
                </div>
                <div className="aboutPage__header-content col-lg-6">
                    <h1>About Us</h1>
                    <p>At 123Admissions, we are dedicated to helping students unlock their academic potential by providing access to top-tier master’s degree programs from over 20 prestigious universities around the globe. Our mission is to streamline the admissions process, making it simpler and more accessible for students who are eager to advance their careers through higher education.</p>
                    <p>In the past year alone, we’ve proudly welcomed over 1,000 students to our platform, guiding them towards the next step in their academic journey. Whether you’re looking for flexibility, quality education, or international opportunities, 123Admissions is your trusted partner for a brighter future.</p>
                    <p>Join us and take the leap towards your master’s degree with the support of world-renowned universities and a team committed to your success!</p>
                </div>
            </div>

            {/* Mission Section */}
            <div className="aboutPage__mission">
                <h2>Our Mission</h2>
                <p>At 123Admissions, our mission is to empower students to achieve their academic and career aspirations by simplifying the admissions process for master’s degree programs. We are dedicated to providing personalized guidance and support, ensuring that each student finds the best fit for their educational journey.</p>
                <p>We take pride in our global partnerships, which allow us to place students in esteemed institutions both abroad and in their home country. Our extensive network of partner universities ensures that students have access to a wide range of opportunities, tailored to their unique goals and preferences.</p>
                <p>At 123Admissions, we believe in:</p>
                <ul>
                    <li><strong>Excellence:</strong> Striving for the highest standards in everything we do.</li>
                    <li><strong>Integrity:</strong> Conducting all our operations with honesty and transparency.</li>
                    <li><strong>Support:</strong> Providing comprehensive support at every step of the admissions process.</li>
                    <li><strong>Global Reach:</strong> Leveraging our international partnerships to open doors to educational opportunities around the world.</li>
                    <li><strong>Student Success:</strong> Focusing on the individual needs of each student.</li>
                </ul>
            </div>

            {/* Journey Section */}
            <div className="aboutPage__journey">
                <h2>Our Journey</h2>
                <p>Since our founding in 2015, 123Admissions has provided comprehensive support at every stage of the educational journey...</p>
                <Timeline />
            </div>

            {/* Students Say Section */}
            <div className="aboutPage__students">
                <h2>What Our Students Say</h2>
                <div className="aboutPage__students-controls">
                    <img src={Left} alt="Left arrow" onClick={handleLeftClick} className="aboutPage__arrow" />
                    <img src={Right} alt="Right arrow" onClick={handleRightClick} className="aboutPage__arrow" />
                </div>
                <div className="aboutPage__students-cards">
                    {cardData.slice(startIndex, startIndex + 1).map((card, index) => (
                        <div key={index} className="aboutPage__students-card">
                            <p>{card.text}</p>
                            <div className="aboutPage__students-card-img">
                                <img src={card.imgSrc} alt={card.name} />
                            </div>
                            <p className="aboutPage__students-card-name">{card.name}</p>
                            <p className="aboutPage__students-card-title">{card.title}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="aboutPage__cta">
                <h2>Collaborate with us to enhance your team’s skills and capabilities.</h2>
                <button className="aboutPage__cta-button">Learn More</button>
            </div>

            {/* Partners Section */}
            <div className="aboutPage__partners">
                <h2>Our Trusted University Partners</h2>
                <p>We are proud to collaborate with leading universities around the globe, providing access to high-quality education from prestigious institutions. Here are some of our esteemed partners:</p>
                <div className="partners__logos">
                    <img src={Assets?.partner1} alt="Partner 1" />
                    <img src={Assets?.partner2} alt="Partner 2" />
                    <img src={Assets?.partner3} alt="Partner 3" />
                    <img src={Assets?.partner4} alt="Partner 4" />
                    <img src={Assets?.partner5} alt="Partner 5" />
                </div>
            </div>

            {/* Available Programs Section */}
            {/* Available Programs Section */}
            <div className="aboutPage__courses">
                <h2>Available Programs</h2>
                <p>Explore our diverse range of master’s programs offered by our university partners.</p>

                <div className="programs__container">
                    <div className="program__card">
                        <h3>MBA (Master of Business Administration)</h3>
                        <ul>
                            <li>Specialization in Marketing</li>
                            <li>Specialization in Finance</li>
                            <li>Specialization in Human Resources</li>
                            <li>Specialization in Information Technology</li>
                            <li>Specialization in Entrepreneurship</li>
                            <li>Specialization in Supply Chain Management</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>MCA (Master of Computer Applications)</h3>
                        <ul>
                            <li>Specialization in Software Development</li>
                            <li>Specialization in Network Security</li>
                            <li>Specialization in Data Science</li>
                            <li>Specialization in Mobile Application Development</li>
                            <li>Specialization in Artificial Intelligence</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>UG (Undergraduate Programs)</h3>
                        <ul>
                            <li>Bachelor of Arts</li>
                            <li>Bachelor of Science</li>
                            <li>Bachelor of Commerce</li>
                            <li>Bachelor of Engineering</li>
                            <li>Bachelor of Design</li>
                            <li>Bachelor of Nursing</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>PG (Postgraduate Programs)</h3>
                        <ul>
                            <li>Postgraduate Diploma in Management</li>
                            <li>Master of Science in Data Analytics</li>
                            <li>Master of Arts in Education</li>
                            <li>Postgraduate Diploma in Marketing Management</li>
                            <li>Postgraduate Diploma in Financial Management</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Arts (MA)</h3>
                        <ul>
                            <li>Specialization in Sociology</li>
                            <li>Specialization in Psychology</li>
                            <li>Specialization in English Literature</li>
                            <li>Specialization in Political Science</li>
                            <li>Specialization in History</li>
                            <li>Specialization in Media Studies</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Science (MSc)</h3>
                        <ul>
                            <li>Specialization in Environmental Science</li>
                            <li>Specialization in Information Technology</li>
                            <li>Specialization in Biotechnology</li>
                            <li>Specialization in Mathematics</li>
                            <li>Specialization in Physics</li>
                            <li>Specialization in Chemistry</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Fine Arts (MFA)</h3>
                        <ul>
                            <li>Specialization in Creative Writing</li>
                            <li>Specialization in Visual Arts</li>
                            <li>Specialization in Performing Arts</li>
                            <li>Specialization in Graphic Design</li>
                            <li>Specialization in Photography</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Public Administration (MPA)</h3>
                        <ul>
                            <li>Specialization in Public Policy</li>
                            <li>Specialization in Nonprofit Management</li>
                            <li>Specialization in Urban Planning</li>
                            <li>Specialization in International Relations</li>
                            <li>Specialization in Public Health Administration</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Health Administration (MHA)</h3>
                        <ul>
                            <li>Specialization in Healthcare Management</li>
                            <li>Specialization in Public Health</li>
                            <li>Specialization in Health Information Technology</li>
                            <li>Specialization in Healthcare Policy</li>
                            <li>Specialization in Hospital Administration</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Engineering (MEng)</h3>
                        <ul>
                            <li>Specialization in Civil Engineering</li>
                            <li>Specialization in Mechanical Engineering</li>
                            <li>Specialization in Electrical Engineering</li>
                            <li>Specialization in Software Engineering</li>
                            <li>Specialization in Structural Engineering</li>
                            <li>Specialization in Environmental Engineering</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Education (MEd)</h3>
                        <ul>
                            <li>Specialization in Curriculum and Instruction</li>
                            <li>Specialization in Educational Leadership</li>
                            <li>Specialization in Special Education</li>
                            <li>Specialization in Adult and Continuing Education</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Architecture (M.Arch)</h3>
                        <ul>
                            <li>Specialization in Sustainable Design</li>
                            <li>Specialization in Urban Design</li>
                            <li>Specialization in Landscape Architecture</li>
                        </ul>
                    </div>
                    <div className="program__card">
                        <h3>Master of Data Science</h3>
                        <ul>
                            <li>Specialization in Big Data</li>
                            <li>Specialization in Machine Learning</li>
                            <li>Specialization in Business Analytics</li>
                        </ul>
                    </div>
                </div>
            </div>


            {/* Success Stories Section */}
            <div className="aboutPage__success-stories">
                <h2>Success Stories</h2>
                <p>Here’s what some of our successful students have to say about their journey with 123Admissions.</p>
                <div className="success-stories__scrollable-container">
                    <div className="success-stories__list">
                        {/* Add each student’s success story here */}
                        <div className="success-story__card">
                            <h3>Jane Doe</h3>
                            <p>"My experience with 123Admissions was fantastic! The team helped me every step of the way."</p>
                        </div>
                        <div className="success-story__card">
                            <h3>John Smith</h3>
                            <p>"Thanks to 123Admissions, I got accepted into my dream master's program!"</p>
                        </div>
                        <div className="success-story__card">
                            <h3>Alice Johnson</h3>
                            <p>"The support I received was invaluable. I couldn't have done it without them!"</p>
                        </div>
                        <div className="success-story__card">
                            <h3>Michael Brown</h3>
                            <p>"Every question I had was answered promptly. I highly recommend their services."</p>
                        </div>
                        <div className="success-story__card">
                            <h3>Emily Davis</h3>
                            <p>"I was guided through every step, and now I'm studying in a top university!"</p>
                        </div>
                        {/* Add more stories as needed */}
                    </div>
                </div>
            </div>



          
        </div>
    );
}

export default AboutusPage;
