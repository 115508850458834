// src/pages/NotFound/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const Navigate = useNavigate()
    return (
        <div style={{ height: "70vh", textAlign: "center" }}>
            <div style={{ marginTop: "20%" }}>
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you're looking for doesn't exist.</p>
                <button className="btn btn-primary" onClick={() => Navigate('/')}>Go Back to Home</button>
            </div>
        </div>
    );
};

export default NotFound;
