// src/components/AboutusPageSample.js

import React from 'react';
import './AboutusPageSample.css';

const AboutusPageSample = () => {
    return (
        <div className="about-us">
            <section className="video-section">
                <h2>About Us</h2>
                <video controls>
                    <source src="path_to_your_video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <p>
                    At 123 Admissions, we are committed to providing the highest quality education and guidance to help students achieve their dreams. Our experienced team of educators and advisors work tirelessly to ensure that each student receives personalized support throughout their academic journey.
                </p>
            </section>

            <section className="alumni-section">
                <h2>Our Alumni</h2>
                <div className="alumni-list">
                    <div className="alumni-item">
                        <img src="https://api.123admissions.com/uploads/student/1717075395832_Prerana-Jain.png" alt="John Doe" />
                        <p>John Doe</p>
                        <p>Software Engineer at Google</p>
                    </div>
                    <div className="alumni-item">
                        <img src="https://api.123admissions.com/uploads/student/1717076199509_tushar%20Ghatke-Jain.png" alt="Jane Smith" />
                        <p>Jane Smith</p>
                        <p>Product Manager at Amazon</p>
                    </div>
                    <div className="alumni-item">
                        <img src="https://api.123admissions.com/uploads/student/1717077797304_Taseena-Jain.png" alt="Emily Johnson" />
                        <p>Emily Johnson</p>
                        <p>Data Scientist at Facebook</p>
                    </div>
                    {/* Additional alumni */}
                    <div className="alumni-item">
                        <img src="path_to_alumni_image.jpg" alt="Alumni Name" />
                        <p>Chris Williams</p>
                        <p>UX Designer at Microsoft</p>
                    </div>
                    <div className="alumni-item">
                        <img src="path_to_alumni_image.jpg" alt="Alumni Name" />
                        <p>Lisa Brown</p>
                        <p>Marketing Manager at Apple</p>
                    </div>
                    <div className="alumni-item">
                        <img src="path_to_alumni_image.jpg" alt="Alumni Name" />
                        <p>James Wilson</p>
                        <p>Financial Analyst at Goldman Sachs</p>
                    </div>
                </div>
            </section>

            <section className="roadmap-section">
                <h2>Our Roadmap</h2>
                <div className="roadmap">
                    <div className="roadmap-item">
                        <h3>Milestone 1: Foundation</h3>
                        <p>We start by laying a strong foundation with essential academic skills and knowledge. Our curriculum is designed to challenge and engage students, preparing them for advanced studies.</p>
                    </div>
                    <div className="roadmap-item">
                        <h3>Milestone 2: Exploration</h3>
                        <p>Students explore various fields of study through electives and extracurricular activities, allowing them to discover their passions and interests.</p>
                    </div>
                    <div className="roadmap-item">
                        <h3>Milestone 3: Specialization</h3>
                        <p>In the later years, students focus on their chosen field of study, gaining in-depth knowledge and practical experience to excel in their future careers.</p>
                    </div>
                    <div className="roadmap-item">
                        <h3>Milestone 4: Graduation</h3>
                        <p>We celebrate our students' achievements as they graduate and move on to prestigious universities and successful careers, equipped with the skills and confidence to make a difference in the world.</p>
                    </div>
                    {/* Additional roadmap milestones */}
                    <div className="roadmap-item">
                        <h3>Milestone 5: Innovation</h3>
                        <p>Encouraging students to think outside the box and come up with innovative solutions to real-world problems through various projects and collaborations.</p>
                    </div>
                    <div className="roadmap-item">
                        <h3>Milestone 6: Leadership</h3>
                        <p>Developing leadership skills through various programs and opportunities that prepare students to take on leadership roles in their future endeavors.</p>
                    </div>
                </div>
            </section>

            <section className="testimonials-section">
                <h2>What Students Say</h2>
                <div className="testimonials">
                    <div className="testimonial-item">
                        <p>"123 Admissions provided me with the guidance and support I needed to get into my dream university. The advisors were always available to help and their advice was invaluable."</p>
                        <p>- Michael Brown</p>
                    </div>
                    <div className="testimonial-item">
                        <p>"The curriculum at 123 Admissions challenged me to think critically and creatively. I feel well-prepared for the challenges of college and beyond."</p>
                        <p>- Sarah Wilson</p>
                    </div>
                    <div className="testimonial-item">
                        <p>"The extracurricular activities offered at 123 Admissions allowed me to explore my interests and develop new skills. I made lifelong friends and memories here."</p>
                        <p>- David Lee</p>
                    </div>
                    {/* Additional testimonials */}
                    <div className="testimonial-item">
                        <p>"The community at 123 Admissions is incredibly supportive. I always felt encouraged and motivated to achieve my best."</p>
                        <p>- Jessica Martinez</p>
                    </div>
                    <div className="testimonial-item">
                        <p>"Thanks to 123 Admissions, I was able to secure multiple internships that greatly enhanced my career prospects."</p>
                        <p>- Robert Anderson</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutusPageSample;
